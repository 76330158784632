@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* ボタン */
  .primarybutton {
    @apply
    rounded-md shadow-md
    px-4 py-3 bg-indigo-600
    text-base font-semibold text-white
    hover:bg-gradient-to-l
    hover:from-indigo-500
    hover:to-indigo-700
    hover:shadow-lg
    active:bg-indigo-800
    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-2
    focus-visible:outline-indigo-600
  }
  .secondarybutton {
    @apply
    rounded-md shadow-sm
    px-4 py-3 bg-white
    text-base font-semibold text-indigo-900
    ring-1 ring-inset ring-gray-300
    hover:bg-indigo-50
    active:bg-indigo-100
    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-2
    focus-visible:outline-indigo-600
  }
  /* テキスト */
  .mainfont {
    @apply
    text-indigo-950
    font-sans
    text-base
    leading-6
  }
  .linkfont {
    @apply
    text-indigo-500
    font-semibold underline decoration-dotted underline-offset-4
  }
  .noticefont {
    @apply
    text-sm text-gray-600
  }
  .errorfont {
    @apply
    text-sm text-red-500
  }
  /* フォーム */
  .labelfont {
    @apply
    inline-block
    mt-4
    text-sm font-semibold leading-6
    sm:text-sm sm:leading-6
  }
  .inputarea {
    @apply
    block w-full
    lg:mt-4 lg:mb-2 mt-2
    rounded-md px-3.5 py-2
    shadow-sm
    ring-1 ring-inset ring-gray-300
    placeholder:text-base placeholder:text-gray-400
    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-2
    focus-visible:outline-indigo-600
  }
  /* アイコン */
  .icon {
    @apply
    w-4 mx-1
    inline-block align-text-bottom
  }
  .iconM {
    @apply
    w-5
    inline-block align-text-bottom
  }
  /* BG */
  .blurBg {
    @apply
    block fixed bottom-0 w-1/2 h-2/3
    rotate-[-30deg]
    blur-2xl -z-10
    bg-gradient-to-r
    from-indigo-50 from-30%
    via-transparent
  }
  .blurSubBg {
    @apply
    block fixed bottom-1/4 left-20 w-1/6 h-1/3
    rotate-45
    blur-3xl -z-10
    bg-gradient-to-r
    from-yellow-50 from-30%
    via-transparent
  }
}